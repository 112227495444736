<template>
  <div class="row">
    <div class="col-12">
      <!-- 사진추가 -->
      <c-card title="LBL0002349" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <!-- 원거리 -->
            <c-upload 
              :attachInfo="attachInfo1"
              label="LBL00001551"
              :editable="editable&&!disabled">
            </c-upload>
          </div>
          <div class="col-12">
            <!-- 근거리 -->
            <c-upload 
              :attachInfo="attachInfo2"
              label="LBL00001552"
              :editable="editable&&!disabled">
            </c-upload>
          </div>
          <div class="col-12">
            <!-- 피해정도 관련 사진 -->
            <c-upload 
              :attachInfo="attachInfo3"
              label="LBL0002340"
              :editable="editable&&!disabled">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'near-relation-picture',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        accidentStatusCd: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editable: true,
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'PROCESS_FIRST_PICTURE',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'PROCESS_SECOND_PICTURE',
        taskKey: '',
      },
      attachInfo3: {
        isSubmit: '',
        taskClassCd: 'PROCESS_DEGREE_DAMAGE',
        taskKey: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    'tabParam.iimAccidentId'() {
      this.setTaskKey();
    },
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setTaskKey();
    },
    setTaskKey() {
      let taskKey = this.tabParam && this.tabParam.iimAccidentId ? this.tabParam.iimAccidentId : '';
      this.attachInfo1.taskKey = taskKey
      this.attachInfo2.taskKey = taskKey
      this.attachInfo3.taskKey = taskKey
    }
  }
};
</script>
