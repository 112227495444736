var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBL0002349" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo1,
                        label: "LBL00001551",
                        editable: _vm.editable && !_vm.disabled,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo2,
                        label: "LBL00001552",
                        editable: _vm.editable && !_vm.disabled,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo3,
                        label: "LBL0002340",
                        editable: _vm.editable && !_vm.disabled,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }